import {
  Button,
  Checkbox,
  Input,
  Radio,
  RadioGroup,
  Textarea,
  Typography,
} from "@mui/joy";

import { IUnitCompleted } from "../../../store/api/umbraco-api";
import { useCallback, useEffect, useState } from "react";
import { concat, debounce, flow, get, uniqBy } from "lodash/fp";
import {
  IFormField,
  FieldTypes,
  fieldIsCheckBox,
  fieldIsRadio,
  fieldIsTextInput,
  fieldIsTextArea,
  fieldIsRichtextContent,
} from "./form-unit-types";
import { FormControlLabel } from "@mui/material";
import RichText from "../../rich-text";

export interface FormUnitProps {
  unit: {
    id: number;
    title: string;
    fields: IFormField[];
    isSmart?: boolean;
  };
  setUnitComplete(req: FormUnitCompletedResponse): void;
  setUnitFinished(finish: boolean): void;
}

//todo hard-coded remove
const hardCodedDefaultCheckedCheckboxes = [
  "Google Calendar",
  "Chat GPT",
  "Forest",
  "הצבת יעדים",
  "איך לתעדף משימות?",
  "איך לא לשכוח שום משימה",
  'בניית לו"ז',
  "שיטת 2 הדקות",
  "שיטת פומודורו",
  "תחקור והשתפרות",
  "Basic Incantations",
];

export interface FormControlProps {
  onChange(value: FieldResponse): void;
  fieldProp: IFormField;
  disabled?: boolean;
}

const FormControl = ({
  fieldProp,
  onChange,
  disabled = false,
}: FormControlProps) => {
  const { id, type, description, label, isDisabled } = fieldProp;

  useEffect(() => {
    let val = "";

    if (fieldIsCheckBox(fieldProp)) {
      if (hardCodedDefaultCheckedCheckboxes.includes(label)) {
        val = "true";
      } else {
        if (get("isChecked", fieldProp) === true) {
          val = "true";
        } else {
          val = "false";
        }
      }
    }

    onChange({
      value: val,
      id,
      type,
    });
  }, []);

  const change = debounce(300, (value: string) => {
    onChange({
      value,
      id,
      type,
    });
  });

  return (
    <>
      {label &&
        type !== FieldTypes.CHECKBOX &&
        type !== FieldTypes.RADIO_BUTTON && <Typography>{label}</Typography>}
      {fieldIsTextArea(fieldProp) && (
        <Textarea
          minRows={fieldProp.minLength}
          placeholder={fieldProp.placeholder}
          maxRows={fieldProp.maxLength}
          disabled={disabled || isDisabled}
          onChange={({ target }) => change(target.value)}
        />
      )}
      {fieldIsTextInput(fieldProp) && (
        <Textarea
          minRows={3}
          placeholder={fieldProp.placeholder}
          disabled={disabled || isDisabled}
          onChange={({ target }) => change(target.value)}
        />
      )}
      {fieldIsRadio(fieldProp) && (
        <>
          <Typography>{label}</Typography>
          <RadioGroup>
            {fieldProp.options.map((op) => (
              <FormControlLabel
                disabled={disabled || isDisabled}
                value={op}
                key={op}
                label={op}
                control={
                  <Radio
                    onChange={({ target }) => change(target.checked.toString())}
                  />
                }
              />
            ))}
          </RadioGroup>
        </>
      )}
      {fieldIsCheckBox(fieldProp) && (
        <FormControlLabel
          disabled={disabled || isDisabled}
          label={label}
          sx={{ gap: 1 }}
          control={
            <Checkbox
              defaultChecked={
                fieldProp.isChecked ||
                hardCodedDefaultCheckedCheckboxes.includes(label)
              }
              onChange={({ target }) => {
                change(target.checked.toString());
              }}
            />
          }
        />
      )}
      {fieldIsRichtextContent(fieldProp) && fieldProp.content && (
        <RichText content={fieldProp.content} />
      )}
      <Typography level="body-xs">{description}</Typography>
    </>
  );
};

const FormUnit = ({
  unit,
  setUnitComplete,
  setUnitFinished,
}: FormUnitProps) => {
  const {
    fields,
    title,
    // id, isSmart
  } = unit;
  // const [smartFormUI, showSmartFormUI] = useState(true);
  const [ch, setCH] = useState<FieldResponse[]>([]);
  const [disabled, setDisabled] = useState(false);

  const onFieldsChanged = useCallback((fieldResponse: FieldResponse) => {
    setCH((ch) => {
      const res = flow(
        concat([fieldResponse]),
        uniqBy<(typeof ch)[0]>("id")
      )(ch);

      return res;
    });
  }, []);

  useEffect(() => {
    setUnitFinished(false);
  }, []);

  const onSendClick = () => {
    setDisabled(true);
    setUnitFinished(true);
    setUnitComplete({ fieldValues: ch });
  };

  // if (smartFormUI) {
  //   return (
  //     <Typography level="title-md">אנחנו עובדים על הטופס שלך...</Typography>
  //   );
  // }

  return (
    <>
      <Typography level="title-md">{title}</Typography>
      {fields.map((field) => (
        <FormControl
          key={field.id}
          onChange={onFieldsChanged}
          fieldProp={field}
          disabled={disabled}
        />
      ))}
      {!disabled && (
        <Button
          sx={{
            width: 150,
            justifySelf: "flex-start",
            alignSelf: "flex-start",
          }}
          disabled={disabled}
          onClick={onSendClick}
          variant="outlined"
        >
          שלח
        </Button>
      )}
    </>
  );
};

export interface FieldResponse {
  id: string;
  type: FieldTypes;
  value: string;
}

interface FormUnitCompletedResponse extends IUnitCompleted {
  fieldValues: FieldResponse[];
}

export default FormUnit;
