import React, { useState } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useSelector } from "react-redux";
import { Box, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import Header from "../header";
import SideBar from "../sidebar";
import { userSelector } from "../../store/session/selectors";

const drawerWidth = 240;

const AuthenticatedRoute: React.FC = () => {
  const user = useSelector(userSelector);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up("md"));

  const handleToggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  if (!user) {
    return <Navigate to="/login" />;
  }

  
  return (
    <Box sx={{ display: "flex", flexDirection: "column", height: "100vh" }}>
      <Header onToggleSidebar={handleToggleSidebar} />
      <Box sx={{ display: "flex", flexGrow: 1, overflow: "hidden" }}>
        {isLargeScreen ? (
          <>
            <Box
              sx={{
                // background: "green",
                height: "100%",
                overflowY: "auto",
                borderLeft: "1px solid rgba(0, 0, 0, 0.12)", // Add left border
              }}
            >
              <SideBar
                mobileOpen={true}
                handleDrawerToggle={handleToggleSidebar}
              />
            </Box>
            <Box
              component="main"
              sx={{
                flexGrow: 1,
                p: 3,
                overflow: "auto",
              }}
            >
              <Outlet />
            </Box>
          </>
        ) : (
          <>
            <Box
              component="main"
              sx={{
                flexGrow: 1,
                p: 3,
                width: "100%",
                overflow: "auto",
              }}
            >
              <Outlet />
            </Box>
            <SideBar
              mobileOpen={isSidebarOpen}
              handleDrawerToggle={handleToggleSidebar}
            />
          </>
        )}
      </Box>
    </Box>
  );
};

export default AuthenticatedRoute;
