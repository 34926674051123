import { useDispatch, useSelector } from "react-redux";
import { Box, IconButton } from "@mui/joy";
import { Person, Menu as MenuIcon } from "@mui/icons-material";
import Avatar from "../avatar";
import LogoBox from "./logo-box";
import { persistor } from "../../store";
import { Divider, Dropdown, Menu, MenuItem, Typography } from "@mui/joy";
import { MenuButton } from "@mui/joy";
import { logout } from "../../store/session/actions/user.actions";
import { usePostAuthLogoutMutation } from "../../store/api/umbraco-api";
import { useNavigate } from "react-router-dom";
import { currentOrganizationSelector, userSelector } from "../../store/session/selectors";
import { TAUGA_AI_LOGO_1 } from "../../images/images";


interface HeaderProps {
  onToggleSidebar: () => void; // This specifies the type for the onToggleSidebar prop
}

const Header: React.FC<HeaderProps> = ({ onToggleSidebar }) => {
  // Receive the toggle function as prop
  const user = useSelector(userSelector);
  const currentOrganization = useSelector(currentOrganizationSelector);
  const [mutate] = usePostAuthLogoutMutation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onPressLogout = async () => {
    try {
      await mutate().unwrap();
      dispatch(logout());
      persistor.purge();
      navigate("login");
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Box
      position={"relative"}
      padding={"15px"}
      height={55}
      flexDirection={"row"}
      display={"flex"}
      justifyContent={"space-between"}
      alignItems={"center"}
      borderBottom="1px solid lightgray"
    >
      <Box sx={{ flexDirection: "row", display: "flex", gap: 2 }}>
        <IconButton
          sx={{ display: { xs: "block", md: "none" } }} // Show on small screens only
          onClick={onToggleSidebar} // Toggle sidebar on click
        >
          <MenuIcon />
        </IconButton>
        <LogoBox imageSrc={TAUGA_AI_LOGO_1} />
        {currentOrganization?.logo ?
          <LogoBox imageSrc={currentOrganization?.logo} />
          : null
        }
      </Box>

      {user && (
        <Dropdown>
          <MenuButton
            slots={{ root: Avatar }}
            slotProps={{ root: { color: "neutral" } }}
          >
            <Avatar color="primary">
              <Person />
            </Avatar>
          </MenuButton>
          <Menu sx={{ textAlign: "center" }}>
            <Typography level="body-xs">שלום {user.firstName}</Typography>
            <Divider />
            <MenuItem onClick={onPressLogout}>התנתק</MenuItem>
          </Menu>
        </Dropdown>
      )}
    </Box>
  );
};

export default Header;
