import { baseApi as api } from "./base-api";
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getAuthLogin: build.query<GetAuthLoginApiResponse, GetAuthLoginApiArg>({
      query: () => ({ url: `/auth/login` }),
    }),
    postAuthLogin: build.mutation<
      PostAuthLoginApiResponse,
      PostAuthLoginApiArg
    >({
      query: (queryArg) => ({
        url: `/auth/login`,
        method: "POST",
        body: queryArg.loginRequest,
      }),
    }),
    postAuthLogout: build.mutation<
      PostAuthLogoutApiResponse,
      PostAuthLogoutApiArg
    >({
      query: () => ({ url: `/auth/logout`, method: "POST" }),
    }),
    postChat: build.mutation<PostChatApiResponse, PostChatApiArg>({
      query: (queryArg) => ({
        url: `/chat`,
        method: "POST",
        body: queryArg.createConversationRequest,
      }),
    }),
    postChatByConversationIdMessages: build.mutation<
      PostChatByConversationIdMessagesApiResponse,
      PostChatByConversationIdMessagesApiArg
    >({
      query: (queryArg) => ({
        url: `/chat/${queryArg.conversationId}/messages`,
        method: "POST",
        body: queryArg.createMessageRequest,
      }),
    }),
    getChatByConversationIdMessages: build.query<
      GetChatByConversationIdMessagesApiResponse,
      GetChatByConversationIdMessagesApiArg
    >({
      query: (queryArg) => ({
        url: `/chat/${queryArg.conversationId}/messages`,
      }),
    }),
    getChatById: build.query<GetChatByIdApiResponse, GetChatByIdApiArg>({
      query: (queryArg) => ({ url: `/chat/${queryArg.id}` }),
    }),
    getCoursesById: build.query<
      GetCoursesByIdApiResponse,
      GetCoursesByIdApiArg
    >({
      query: (queryArg) => ({ url: `/courses/${queryArg.id}` }),
    }),
    getOrganizations: build.query<
      GetOrganizationsApiResponse,
      GetOrganizationsApiArg
    >({
      query: () => ({ url: `/organizations` }),
    }),
    getFeaturesByFeatureId: build.query<
      GetFeaturesByFeatureIdApiResponse,
      GetFeaturesByFeatureIdApiArg
    >({
      query: (queryArg) => ({ url: `/features/${queryArg.featureId}` }),
    }),
    postFeaturesByFeatureId: build.mutation<
      PostFeaturesByFeatureIdApiResponse,
      PostFeaturesByFeatureIdApiArg
    >({
      query: (queryArg) => ({
        url: `/features/${queryArg.featureId}`,
        method: "POST",
        body: queryArg.iUnitCompleted,
      }),
    }),
    postUploadByUnitId: build.mutation<
      PostUploadByUnitIdApiResponse,
      PostUploadByUnitIdApiArg
    >({
      query: (queryArg) => ({
        url: `/upload/${queryArg.unitId}`,
        method: "POST",
        body: queryArg.body,
      }),
    }),
    getLoginProvidersByProviderUrlName: build.query<
      GetLoginProvidersByProviderUrlNameApiResponse,
      GetLoginProvidersByProviderUrlNameApiArg
    >({
      query: (queryArg) => ({
        url: `/loginProviders/${queryArg.providerUrlName}`,
      }),
    }),
    getStatsCourseByCourseId: build.query<
      GetStatsCourseByCourseIdApiResponse,
      GetStatsCourseByCourseIdApiArg
    >({
      query: (queryArg) => ({ url: `/stats/course/${queryArg.courseId}` }),
    }),
    getStatsExportCourseByCourseId: build.query<
      GetStatsExportCourseByCourseIdApiResponse,
      GetStatsExportCourseByCourseIdApiArg
    >({
      query: (queryArg) => ({
        url: `/stats/export/course/${queryArg.courseId}`,
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as umbracoApi };
export type GetAuthLoginApiResponse = /** status 200 Success */ LoginResponse;
export type GetAuthLoginApiArg = void;
export type PostAuthLoginApiResponse = /** status 200 Success */ LoginResponse;
export type PostAuthLoginApiArg = {
  loginRequest: LoginRequest;
};
export type PostAuthLogoutApiResponse = unknown;
export type PostAuthLogoutApiArg = void;
export type PostChatApiResponse =
  /** status 200 Success */ CreateConversationResponse;
export type PostChatApiArg = {
  createConversationRequest: CreateConversationRequest;
};
export type PostChatByConversationIdMessagesApiResponse =
  /** status 200 Success */ CreateMessageRequest;
export type PostChatByConversationIdMessagesApiArg = {
  conversationId: string;
  createMessageRequest: CreateMessageRequest;
};
export type GetChatByConversationIdMessagesApiResponse =
  /** status 200 Success */ MessageDto[];
export type GetChatByConversationIdMessagesApiArg = {
  conversationId: string;
};
export type GetChatByIdApiResponse =
  /** status 200 Success */ GetConversationResponse;
export type GetChatByIdApiArg = {
  id: string;
};
export type GetCoursesByIdApiResponse = /** status 200 Success */ CourseContent;
export type GetCoursesByIdApiArg = {
  id: number;
};
export type GetOrganizationsApiResponse =
  /** status 200 Success */ Organization[];
export type GetOrganizationsApiArg = void;
export type GetFeaturesByFeatureIdApiResponse =
  /** status 200 Success */ FeatureresponseWithUnit;
export type GetFeaturesByFeatureIdApiArg = {
  featureId: number;
};
export type PostFeaturesByFeatureIdApiResponse = unknown;
export type PostFeaturesByFeatureIdApiArg = {
  featureId: number;
  iUnitCompleted: IUnitCompleted;
};
export type PostUploadByUnitIdApiResponse = unknown;
export type PostUploadByUnitIdApiArg = {
  unitId: number;
  body: {
    files?: Blob[];
  };
};
export type GetLoginProvidersByProviderUrlNameApiResponse =
  /** status 200 Success */ LoginProviderResponse;
export type GetLoginProvidersByProviderUrlNameApiArg = {
  providerUrlName: string;
};
export type GetStatsCourseByCourseIdApiResponse = unknown;
export type GetStatsCourseByCourseIdApiArg = {
  courseId: number;
};
export type GetStatsExportCourseByCourseIdApiResponse = unknown;
export type GetStatsExportCourseByCourseIdApiArg = {
  courseId: number;
};
export type LoginResponse = {
  email?: string;
  firstName?: string;
  lastName?: string;
  dateOfBirth?: string;
  isTeacher?: boolean;
};
export type ProblemDetails = {
  type?: string | null;
  title?: string | null;
  status?: number | null;
  detail?: string | null;
  instance?: string | null;
  [key: string]: any;
};
export type LoginRequest = {
  username?: string;
  password?: string;
  keepMeLoggedIn?: boolean;
};
export type CreateConversationResponse = {
  conversationId?: string;
  message?: string;
};
export type MessageSender = "AI" | "Student";
export type MessageDto = {
  id?: string;
  time?: string;
  sender?: MessageSender;
  content?: string;
};
export type ConversationExistResponse = {
  conversationId?: string;
  existingMessages?: MessageDto[];
};
export type CreateConversationRequest = {
  initialMessage?: string;
  resourceId?: number | null;
};
export type CreateMessageRequest = {
  message?: string;
};
export type GetConversationResponse = {
  id?: string;
  isOpen?: boolean;
};
export type FeatureType = "Exercise" | "Lesson" | "SmartLesson";
export type ProgressStatus = "NotStarted" | "InProgress" | "Finished";
export type Feature = {
  id?: number;
  type?: FeatureType;
  name?: string;
  description?: string;
  isLocked?: boolean;
  status?: ProgressStatus;
  totalUnitNumber?: number;
  completedUnitNumber?: number;
  group?: string;
};
export type CourseContent = {
  id?: number;
  name?: string;
  logo?: string;
  shortDescription?: string;
  features?: Feature[];
};
export type Course = {
  id?: number;
  name?: string;
};
export type Organization = {
  name?: string;
  logo?: string;
  courses?: Course[];
};
export type UnitTypes =
  | "ContentUnit"
  | "OpenResponseExercise"
  | "MultiChoiceExercise"
  | "Form"
  | "AIConversation";
export type IUnit = {
  id?: number;
  type?: UnitTypes;
};
export type FeatureResponse = {
  id?: number;
  unitsTotal?: number;
  unitsCompleted?: number;
  status?: ProgressStatus;
};
export type FeatureresponseWithUnit = {
  unit?: IUnit;
  feature?: FeatureResponse;
};
export type IUnitCompleted = {
  id?: number;
  type?: UnitTypes;
  start?: string;
  end?: string;
};
export type LoginProviderResponse = {
  courseId?: number;
  courseName?: string;
  logoUrl?: string;
};
export const {
  useGetAuthLoginQuery,
  usePostAuthLoginMutation,
  usePostAuthLogoutMutation,
  usePostChatMutation,
  usePostChatByConversationIdMessagesMutation,
  useGetChatByConversationIdMessagesQuery,
  useGetChatByIdQuery,
  useGetCoursesByIdQuery,
  useGetOrganizationsQuery,
  useGetFeaturesByFeatureIdQuery,
  usePostFeaturesByFeatureIdMutation,
  usePostUploadByUnitIdMutation,
  useGetLoginProvidersByProviderUrlNameQuery,
  useGetStatsCourseByCourseIdQuery,
  useGetStatsExportCourseByCourseIdQuery,
} = injectedRtkApi;
