import React, { useState, useRef, useEffect, useMemo } from "react";
import { FaRegImages, FaCamera } from "react-icons/fa";
import { Box, Textarea, Typography } from "@mui/joy";
import { isEmpty } from "lodash/fp";
import { Button, Card } from "..";
import RichText from "../rich-text";
import { themeBase } from "../../theme";
import { REACT_APP_SERVER_DOMAIN } from "../../base-env";
import LoadingIndicator from "../common/loading-indicator";

interface Question {
  id: number;
  title: string;
  solution?: string | null;
  question: string;
  answerType: string;
}

interface ExerciseQuestionProps extends Question {
  onAnswerClick?: boolean;
  disabled?: boolean;
  setUnitFinished(finish: boolean): void;
  setUnitComplete(args: any): void;
}

enum QuestionState {
  ANSWERING,
  WRONG_ANSWER,
  CORRECT_ANSWER,
}

const OpenExerciseQuestion = ({
  title,
  solution,
  question,
  setUnitFinished,
  setUnitComplete,
  answerType,
  id,
}: ExerciseQuestionProps) => {
  const [text, setText] = useState("");
  const [images, setImages] = useState<File[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [questionState, setQuestionState] = useState<QuestionState>(
    QuestionState.ANSWERING
  );
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const cameraInputRef = useRef<HTMLInputElement | null>(null);

  const isAnswered = useMemo(
    () => questionState !== QuestionState.ANSWERING,
    [questionState]
  );

  const onAnswer = async () => {
    try {
      setIsLoading(true);

      if (answerType === "image" && images.length > 0) {
        const formData = new FormData();
        images.forEach((file) => {
          formData.append("files", file);
        });

        const response = await fetch(
          `${REACT_APP_SERVER_DOMAIN}/upload/${id}`,
          {
            method: "POST",
            body: formData,
            credentials: "include", // Add this if you need to send cookies
          }
        );

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const imagesUrls = await response.json();
        const imagesUrlsString = imagesUrls.join(",");

        setQuestionState(QuestionState.CORRECT_ANSWER);
        setUnitComplete({ answer: imagesUrlsString });
      } else if (answerType !== "image" && !isEmpty(text)) {
        setQuestionState(QuestionState.CORRECT_ANSWER);
        setUnitComplete({ answer: text });
      } else {
        console.log("No valid answer to submit.");
      }
    } catch (error) {
      console.error("Failed to upload images or submit text:", error);
      setQuestionState(QuestionState.WRONG_ANSWER);
    } finally {
      setIsLoading(false);
    }
  };

  const handleImageUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const selectedFiles = Array.from(e.target.files);
      setImages((prevImages) => [...prevImages, ...selectedFiles]);
    }
  };

  const handleButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleCameraClick = () => {
    if (cameraInputRef.current) {
      cameraInputRef.current.click();
    }
  };

  useEffect(() => {
    setUnitFinished(isAnswered);
  }, [isAnswered, setUnitFinished]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 2,
        maxWidth: "600px",
      }}
    >
      {isLoading ? (
        <LoadingIndicator />
      ) : (
        <>
          <Typography level="title-lg">{title}</Typography>
          <RichText content={question} />

          {answerType === "image" ? (
            <>
              <input
                type="file"
                accept="image/*"
                multiple
                disabled={isAnswered}
                onChange={handleImageUpload}
                ref={fileInputRef}
                style={{ display: "none" }}
              />
              <input
                type="file"
                accept="image/*"
                capture="environment"
                disabled={isAnswered}
                onChange={handleImageUpload}
                ref={cameraInputRef}
                style={{ display: "none" }}
              />
              <Box sx={{ display: "flex", gap: 2 }}>
                <Button
                  sx={{ gap: 2 }}
                  onClick={handleButtonClick}
                  disabled={isAnswered}
                  variant="outlined"
                >
                  <FaRegImages />
                  העלה תמונות
                </Button>
                <Button
                  sx={{ gap: 2 }}
                  onClick={handleCameraClick}
                  disabled={isAnswered}
                  variant="outlined"
                >
                  <FaCamera />
                  צלם תמונה
                </Button>
              </Box>
              {images.length > 0 && (
                <Box sx={{ gap: 1, display: "grid" }}>
                  {images.map((image, index) => (
                    <Box
                      key={index}
                      sx={{
                        bgcolor:
                          themeBase.colorSchemes.light.palette.primary
                            .gray_light,
                        padding: 1,
                        borderRadius: "5px",
                      }}
                    >
                      <Typography fontSize={12}>{image.name}</Typography>
                    </Box>
                  ))}
                </Box>
              )}
            </>
          ) : (
            <Textarea
              minRows={4}
              disabled={isAnswered}
              onChange={(e) => setText(e.target.value)}
            />
          )}

          {isAnswered && solution && (
            <Card>
              <RichText content={solution} />
            </Card>
          )}

          {!isAnswered && (
            <Button
              sx={{
                width: 150,
                justifySelf: "flex-start",
                alignSelf: "flex-start",
              }}
              disabled={
                answerType === "image" ? images.length === 0 : isEmpty(text)
              }
              onClick={onAnswer}
              variant="outlined"
            >
              שלח תשובה
            </Button>
          )}

          <span style={{ alignSelf: "center" }} id="rewardId" />
        </>
      )}
    </Box>
  );
};

export default OpenExerciseQuestion;
