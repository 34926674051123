import React from "react";
import { Box, Drawer } from "@mui/material";
import { Close, Home } from "@mui/icons-material";
import { useLocation, useNavigate } from "react-router-dom";
import { IconButton, Typography } from "@mui/joy";
import { isEmpty } from "lodash";
import SideBarNavButton from "./components/sidebar-nav-button";
import Section from "./components/section";
import { Course, Organization, useGetOrganizationsQuery } from "../../store/api/umbraco-api";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useDispatch } from "react-redux";
import { setCurrentOrganization } from "../../store/session/actions/organization.actions";

const drawerWidth = 240;

interface SideBarProps {
  mobileOpen: boolean;
  handleDrawerToggle: () => void;
}

interface CourseWithOrg extends Course {
  orgName?: string;
  orgLogo?: string;
};

const SideBar: React.FC<SideBarProps> = ({ mobileOpen, handleDrawerToggle }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { data } = useGetOrganizationsQuery();
  const dispatch = useDispatch();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

  const courses = React.useMemo(
    () => (data ? data.reduce((prev, curr) => prev.concat(...(curr.courses?.map(course => ({...course, orgName: curr.name, orgLogo: curr.logo })) || [])), [] as CourseWithOrg[]) : []),
    [data]
  );

  const drawerContent = (
    <Box
      sx={{
        gap: 1,
        padding: 3,
        flexDirection: "column",
        display: "flex",
        height: '100%',
        overflowY: 'auto',
      }}
    >
      {!isDesktop && mobileOpen && (
        <IconButton onClick={handleDrawerToggle} sx={{ alignSelf: "flex-end", mb: 2 }}>
          <Close />
        </IconButton>
      )}
      <Section>
        <SideBarNavButton
          Icon={<Home fontSize="small" sx={{ ml: 1 }} />}
          text="בית"
          checked={location.pathname === `/home` || location.pathname === `/`}
          onClick={() => {
                dispatch(setCurrentOrganization("", undefined));
            navigate(`home`)
          }}
        />
      </Section>
      {!isEmpty(courses) && (
        <Section>
          <Typography>הקורסים שלי</Typography>
          {courses.map(({ id, name, orgName, orgLogo }) => (
            <SideBarNavButton
              key={id}
              checked={location.pathname.includes(`/course/${id}`)}
              text={name || ""}
              onClick={() => {
                dispatch(setCurrentOrganization(orgName || "", orgLogo));
                navigate(`/course/${id}`);
              }}
            />
          ))}
        </Section>
      )}
    </Box>
  );

  return (
    <Box component="nav" sx={{ width: { md: drawerWidth }, flexShrink: { md: 0 } }}>
      <Drawer
        variant="temporary"
        anchor="right"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{ keepMounted: true }}
        sx={{
          display: { xs: 'block', md: 'none' },
          '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
        }}
      >
        {drawerContent}
      </Drawer>
      <Drawer
        variant="permanent"
        sx={{
          height:'100%',
          display: { xs: 'none', md: 'block' },
          '& .MuiDrawer-paper': { 
            boxSizing: 'border-box', 
            width: drawerWidth, 
            position: 'relative',
            borderLeft: '1px solid rgba(0, 0, 0, 0.12)', // Add left border
            borderRight: 'none', 
          },
        }}
        open
      >
        {drawerContent}
      </Drawer>
    </Box>
  );
};

export default SideBar;